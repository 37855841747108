import { render, staticRenderFns } from "./ProximidadeAnalise.vue?vue&type=template&id=2d27f5de&scoped=true&"
import script from "./ProximidadeAnalise.vue?vue&type=script&lang=js&"
export * from "./ProximidadeAnalise.vue?vue&type=script&lang=js&"
import style0 from "./ProximidadeAnalise.vue?vue&type=style&index=0&id=2d27f5de&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d27f5de",
  null
  
)

export default component.exports