<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-btn color="secondary" to="/relatorios/proximidade/filtros">
          <v-icon left v-text="'mdi-chevron-left'" />
          Voltar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card class="ma-0 pa-3">
          <v-simple-table dense id="tabela">
            <template v-slot:default>
              <tbody>
                <tr v-for="(linha, key) in dados" :key="key">
                  <td
                    class="caption px-1 text-center"
                    style="
                      border-right: 1px solid grey;
                      background-color: #f5f5f5;
                    "
                  >
                    <b>{{ key + 1 }}</b>
                  </td>

                  <template v-for="(passagem, keyLinha) in linha.esquerda">
                    <proximidade-analise-item
                      :key="`e${keyLinha}`"
                      :passagem="passagem"
                      :max-coincidencias="maxCoincidencias"
                    />
                  </template>

                  <td class="grey lighten-2 text-center">
                    <v-chip
                      v-b-tooltip.hover.left.html="linha.texto"
                      class="subtitle-2 px-2 py-1 white--text"
                      label
                      @click="enviarPlaca(linha.leitura.placa)"
                      small
                      color="black"
                    >
                      {{ linha.leitura.placa }}
                    </v-chip>
                  </td>
                  <template v-for="(passagem, keyLinha) in linha.direita">
                    <proximidade-analise-item
                      :key="`d${keyLinha}`"
                      :passagem="passagem"
                      :max-coincidencias="maxCoincidencias"
                    />
                  </template>
                  <td
                    class="caption px-1 text-center"
                    style="background-color: #f5f5f5"
                  >
                    <b>{{ key + 1 }}</b>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import dataFormatada from "@/utils/formatar-data-input";
export default {
  components: {
    ProximidadeAnaliseItem: () => import("./ProximidadeAnaliseItem"),
  },

  data() {
    return {
      dados: [],
      maxCoincidencias: 0,
      dadosPlacaMeio: [],
    };
  },

  mounted() {
    this.organizarResultado();
    this.$store.commit("layout/SET_DRAWER", false);
    this.dataFormatacao();
  },

  beforeDestroy() {
    this.$store.commit("layout/SET_DRAWER", true);
    // this.$store.commit('relatorioProximidade/SET_RESULTADO', {})
  },

  methods: {
    async enviarPlaca(placa) {
      if (placa === null) {
        return;
      }
      try {
        let routeData = this.$router.resolve({
          name: "/?placa=",
          query: { placa: placa },
        });
        window.open(routeData.href, "_blank");
      } catch (e) {
        console.log(e);
      }
    },
    dataFormatacao() {
      this.dados.map((item) => {
        item.leitura.data_registro;
        item.leitura.data_registro = dataFormatada.ptBrComHorario(
          item.leitura.data_registro
        );
        console.log(item.leitura.data_registro), "AAAA";
      });
    },
    organizarResultado() {
      const resultado = _.cloneDeep(
        this.$store.state.relatorioProximidade.resultado
      );
      console.log(resultado, "resultado");

      resultado.map((item) => {
        console.log(item.leitura, "item.leitura");

        this.dadosPlacaMeio.push(item.leitura);
      });
      this.dados = [];
      let maxPassagensEsquerda = 0;
      let maxPassagensDireita = 0;

      for (var item of resultado) {
        const linha = {
          leitura: item.leitura,
          texto: "",
          esquerda: [],
          direita: [],
        };

        const dataLeitura = new Date(item.leitura.data_registro);

        for (var passagem of item.passagens) {
          const dataPassagem = new Date(passagem.data_registro);

          const horarioPassagem = passagem.data_registro;
          const horarioLeitura = linha.leitura.data_registro;

          var date1 = new Date(horarioPassagem);
          var date2 = new Date(horarioLeitura);

          const calculoData = showDiff(date1, date2);
          passagem.data_registro_diferenca = calculoData;

          function showDiff(date1, date2) {
            var diff = (date2 - date1) / 1000;
            diff = Math.abs(Math.floor(diff));

            var days = Math.floor(diff / (24 * 60 * 60));
            var leftSec = diff - days * 24 * 60 * 60;

            var hrs = Math.floor(leftSec / (60 * 60));
            var leftSec = leftSec - hrs * 60 * 60;

            var min = Math.floor(leftSec / 60);
            var leftSec = leftSec - min * 60;
            ("0" + Number(hrs)).slice(-2) +
              ":" +
              ("0" + Number(min)).slice(-2) +
              ":" +
              ("0" + Number(leftSec)).slice(-2);

            return (
              ("0" + Number(hrs)).slice(-2) +
              ":" +
              ("0" + Number(min)).slice(-2) +
              ":" +
              ("0" + Number(leftSec)).slice(-2)
            );
          }

          if (
            this.$store.state.relatorioProximidade.filtros
              .veiculosSemCoincidencia === true ||
            passagem.totalCoincidencia > 1
          ) {
            if (dataPassagem < dataLeitura) {
              linha.esquerda.push(passagem);
            } else {
              linha.direita.push(passagem);
            }
          }

          if (passagem.totalCoincidencia > this.maxCoincidencias) {
            this.maxCoincidencias = passagem.totalCoincidencia;
          }
        }

        if (linha.esquerda.length > maxPassagensEsquerda) {
          maxPassagensEsquerda = linha.esquerda.length;
        }

        if (linha.direita.length > maxPassagensDireita) {
          maxPassagensDireita = linha.direita.length;
        }

        this.dados.push(linha);
        linha.texto =
          "<p>Hora: " +
          dataFormatada.ptBrComHorario(linha.leitura.data_registro) +
          "</p><p>Latitude: " +
          linha.leitura.latitude +
          "</p><p>Identificador da câmera: " +
          linha.leitura.identificador +
          "</p><p>Ponto de captura: " +
          linha.leitura.ponto_captura_descricao +
          "</p>";
      }

      // Caso as linhas tenham quantidade de placas diferentes, deixa todas iguais
      for (var linha of this.dados) {
        if (linha.esquerda.length < maxPassagensEsquerda) {
          var m = maxPassagensEsquerda - linha.esquerda.length;
          for (var i = 0; i < m; i++) {
            linha.esquerda.unshift({ placa: null });
          }
        }

        if (linha.direita.length < maxPassagensDireita) {
          var m = maxPassagensDireita - linha.direita.length;
          for (var j = 0; j < m; j++) {
            linha.direita.push({});
          }
        }
      }

      // setTimeout para dar tempo de renderizar a tabela na tela
      setTimeout(() => {
        const tabela = document.querySelector("#tabela .v-data-table__wrapper");
        const item = document.getElementsByClassName("item")[0];

        tabela.scrollTo(
          (maxPassagensEsquerda + 1) * item.clientWidth -
            tabela.clientWidth / 2,
          0
        );
      }, 500);
    },
  },
};
</script>
<style scoped>
.v-tooltip__content {
  opacity: 1 !important;
}
</style>